import React, { FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    Paper,
    Typography,
    Box,
    Grid,
    Link,
} from '@material-ui/core';
import CopyRight from 'components/CopyRight';
import colors from 'colors';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 60,
        backgroundColor: colors.lightGrey,
    },
    innerContainer: {
        padding: '40px 56px',
        width: 'calc(100% - 120px)',
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            padding: '28px',
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    cardLink: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));

const Moe: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Paper className={classes.innerContainer} elevation={1}>
                <Box textAlign="center">
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Typography align="left" variant="h1" style={{ paddingBottom: 24 }}>
                                Playground MOE Demo Page
                            </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <Link href="/faq" style={{ textDecoration: 'none' }}>
                                <Typography variant="h1" style={{ paddingBottom: 24 }}>
                                    FAQ
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                    <div className="content" style={{ paddingBottom: 1000 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ paddingTop: 300 }}>
                                <div className="header">
                                    <Typography variant="h3" style={{ paddingBottom: 24 }}>
                                        Ministry of Education
                                    </Typography>
                                    <Typography variant="subtitle1" style={{ paddingBottom: 200 }}>
                                        Moulding the future of our nation.
                                    </Typography>
                                </div>
                                <Grid alignItems="center" direction='column' justify='center'>
                                <div className="body">
                                        <div
                                            id="searchsg-searchbar"
                                            data-client-id="c1fa2992-c5a7-4481-9abb-e4ef6b97b1eb"
                                            data-override-display-name="MOE"
                                            data-display-new="true"
                                        />
                                    </div>
                        
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Paper>
            <CopyRight />
        </div>
    );
};

export default Moe;
